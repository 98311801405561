<template>
  <div class="multi-picture-select">
    <question-title class="q-mb16 q-mt0">{{ $t(title) }}</question-title>
    <div
      class="multi-picture-select__options multi-select-options flex flex-wrap--wrap flex--space-between--center"
      :aria-label="$t(title)"
      data-question
      tabindex="-1"
      role="group"
    >
      <template v-for="option of options">
        <picture-select
          v-if="!option.askForOtherInput"
          :key="option.value"
          class="multi-select-options__picture-select q-mb24"
          role="checkbox"
          :class="pictureSelectClasses"
          :value="option.value"
          :dysfunction="dysfunction"
          :ai-selected="option.aiSelected"
          :gender="gender"
          :active="getActiveValue(option.value)"
          tabindex="0"
          @input="onInput(option)"
        />

        <q-checkbox
          v-else
          :key="option.value"
          :checked="getIsChecked(option.value)"
          :dysfunction="dysfunction"
          :text="option.text"
          @change="onInput(option)"
        />
      </template>

      <u-textarea
        v-if="isOtherInputFieldVisible"
        class="q-mt8 q-full-width"
        :value="otherValue"
        @input="$emit('other-input', $event)"
      />
    </div>
  </div>
</template>

<script>
import { UTextarea } from 'universkin-shared';

import PictureSelect from '@/modules/questionnaire/new-design-components/inputs/PictureSelect.vue';
import QCheckbox from '@/modules/questionnaire/new-design-components/inputs/QCheckbox';
import QuestionTitle from '@/modules/questionnaire/new-design-components/questions/QuestionTitle';

export default {
  name: 'MultiPictureSelect',
  components: { PictureSelect, QuestionTitle, QCheckbox, UTextarea },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: [String, Array],
      required: true
    },
    otherValue: {
      type: String,
      default: ''
    },
    gender: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    dysfunction: {
      type: String,
      default: ''
    }
  },
  computed: {
    isOtherInputFieldVisible() {
      return this.value.includes('other');
    },
    pictureSelectClasses() {
      return {
        'multi-select-options__picture-select--three-elements-per-row': this.options.length === 3
      };
    }
  },
  methods: {
    getActiveValue(value) {
      if (Array.isArray(this.value)) {
        return this.value.includes(value) ? value : '';
      }

      return this.value === value ? value : '';
    },
    getIsChecked(value) {
      return this.value.includes(value);
    },
    clearOtherValue() {
      this.$emit('other-input', '');
    },
    onInput({ value }) {
      if (value === 'other' && this.getIsChecked(value)) {
        this.clearOtherValue();
      }

      if (!Array.isArray(this.value)) {
        this.$emit('input', value);
        return;
      }

      if (this.value.includes(value)) {
        const filteredValues = this.value.filter(item => item !== value);

        this.$emit('input', filteredValues);
        return;
      }

      this.$emit('input', [...this.value, value]);
    }
  }
};
</script>

<style lang="scss" scoped>
.multi-picture-select {
  &__options {
    flex-direction: row;
    align-items: stretch;
  }
}

.multi-select-options {
  &__picture-select {
    flex: 1 1 45%;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    &--three-elements-per-row {
      flex: 1 1 30%;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
</style>
